<template>
  <div class="jump">
    <!--    <p style="font-size: 24px">-->
    <!--      正在跳转到支付页面，请稍候~-->
    <!--    </p>-->
    <div><img src="@/assets/images/loading.gif" alt=""></div>
  </div>
</template>

<script>
import { getRedirectUrl, oauthAlipayInfoDo, oauthInfoDo } from '@/api/api'
import config from '@/config'
import wayCode from '@/utils/wayCode'

// 分发器页面，  用户扫码后进入的第一个页面：
// 判断是否存在channelUserId, 如果存在则直接跳到 对应的支付页面，如果不存在则重定向到对应的页面。
export default {
  data() {
    return {
      payToken: ''
    }
  },
  mounted() {
    const payCode = wayCode.getPayWay()
    console.log(payCode)
    debugger
    if (payCode.wayCode === 'WX_JSAPI') {
      const authCode = this.$route.query['code']
      this.payToken = this.$route.params[config.urlTokenName]
      const redirect = encodeURIComponent(window.location.protocol + '//' + window.location.host + this.$route.path)
      if (!authCode) {
        // 无code
        getRedirectUrl(this.payToken, redirect).then(res => {
          if (res === '') {
            this.$router.push({ name: config.errorPageRouteName, params: { errInfo: '请重新扫码' }})
          }
          location.href = res
        }).catch(res => {
          this.$router.push({ name: config.errorPageRouteName, params: { errInfo: res.message }})
        })
      } else {
        oauthInfoDo(this.payToken, authCode).then(res => {
          this.dispatchPay()
        }).catch(res => {
          this.$router.push({ name: config.errorPageRouteName, params: { errInfo: res.message }})
        })
      }
    }

    if (payCode.wayCode === 'ALI_JSAPI') {
      const authCode = this.$route.query['auth_code']
      this.payToken = this.$route.params[config.urlTokenName]
      const redirect = encodeURIComponent(window.location.protocol + '//' + window.location.host + this.$route.path)
      if (!authCode) {
        // 无code
        getRedirectUrl(this.payToken, redirect).then(res => {
          if (res === '') {
            this.$router.push({ name: config.errorPageRouteName, params: { errInfo: '请重新扫码' }})
            return
          }
          location.href = res
        }).catch(res => {
          this.$router.push({ name: config.errorPageRouteName, params: { errInfo: res.message }})
        })
      } else {
        oauthAlipayInfoDo(this.payToken, authCode).then(resp => {
          this.dispatchPay()
        }).catch(res => {
          this.$router.push({ name: config.errorPageRouteName, params: { errInfo: res.message }})
        })
      }
    }
  },
  methods: {
    dispatchPay() {
      // 获取不到支付类型, 需要跳转到Blank页面
      const payCode = wayCode.getPayWay()
      if (payCode.wayCode === 'WX_JSAPI') {
        this.$router.push({ name: payCode.routeName, params: {
          'ppapiPay': this.payToken
        }})
        return false
      }
      if (payCode.wayCode === 'ALI_JSAPI') {
        this.$router.push({ name: payCode.routeName, params: {
          'ppapiPay': this.payToken
        }})
        return false
      }
    }

  }

}
</script>
<style lang="css" scoped>
  .jump {
    height:100vh;
    width: 100vw;
    display:flex;
    justify-content: center;
    align-items: center;
  }
</style>
